/* TypoGraphy */
* {
	box-sizing: border-box;
}

:focus {
	outline: none !important;
	box-shadow: none;
	border-color: inherit;
}

figure {
	margin: 0 0 0;
}

textarea.form-control {
	resize: none;
	overflow: hidden;
}

html {
	overflow-x: hidden;
	scroll-behavior: smooth;
}

html,
body {
	width: 100%;
}

body {
	padding: 0;
	margin: 0;
	font-family: 'Gotham-Book';
	font-weight: normal;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background: #20204a;
	/* overflow-x: hidden; */
}

/* body {
    overflow-x: hidden;
} */

.footer {
	margin-top: auto;
}

img {
	max-width: 100%;
}

a {
	transition: 0.45s;
	-webkit-transition: 0.45s;
	-moz-transition: 0.45s;
	color: #3b4b9b;
}

a:hover {
	color: #3b4b9b;
	text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0px 0 15px;
	padding: 0px;
	color: #2e2e2e;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	color: #2e2e2e;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
	color: #c39f5e;
}

h1 {
	font-family: 'Gotham Black';
	font-weight: 900;
	font-size: 6.944vw;
	line-height: 6.597vw;
	color: #f2f2f2;
	letter-spacing: -0.139vw;
}
h2 {
	font-family: 'Gotham Black';
	font-weight: 900;
	font-size: 4.167vw;
	line-height: 4.167vw;
	color: #f2f2f2;
	margin-bottom: 20px;
	letter-spacing: -0.042vw;
}

h3 {
	font-family: 'Gotham Bold';
	font-weight: bold;
	font-size: 1.6em;
	line-height: 1.25em;
	color: #f2f2f2;
	margin-bottom: 20px;
}

h4 {
	font-family: 'Gotham Bold';
	font-weight: bold;
	font-size: 22px;
	line-height: 28px;
}

h5 {
	font-family: 'Gotham Bold';
	font-weight: bold;
	font-size: 16px;
	line-height: 19.36px;
}

h6 {
	font-family: 'Gotham Bold';
	font-weight: bold;
	font-size: 16px;
	line-height: 19.36px;
}
p {
	font-size: 1.111vw;
	color: #f2f2f2;
	line-height: 1.528vw;
	margin-bottom: 25px;
	font-family: 'Gotham Book';
	font-weight: normal;
	font-style: normal;
	letter-spacing: 0.12px;
}
p a {
	color: #3b4b9b;
	text-decoration: underline;
}
p a:hover {
	color: #3b4b9b;
	text-decoration: none;
}
b,
strong {
	font-family: 'Gotham Bold';
	font-weight: bold;
}
p:last-child {
	margin-bottom: 0;
}

ul {
	padding: 0px 0 20px 0;
	margin: 0px;
}

ol {
	padding-left: 40px;
	margin: 0;
	padding-bottom: 0px;
}

ol li {
	position: relative;
	margin: 0px;
	padding: 0 0;
	list-style: decimal-leading-zero;
	font-style: normal;
	font-size: 1.3em;
	color: #3b4b9b;
	line-height: 1.55em;
	position: relative;
}
li {
	margin: 0px;
	padding: 0 0;
	list-style: none;
	font-style: normal;
	font-size: 1.3em;
	color: #3b4b9b;
	line-height: 1.55em;
	position: relative;
}
li:last-child {
	margin-bottom: 0;
}

button {
	padding: 0;
	background-color: transparent;
	border: none;
}

.btn {
	margin: 0;
	padding: 0;
	border: 0.139vw solid #f2f2f2;
	min-width: 14.167vw;
	height: 4.444vw;
	color: #f2f2f2;
	font-size: 1.35vw;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 3.125vw;
	font-family: 'Gotham Medium';
	font-weight: 500;
	position: relative;
	overflow: hidden;
	z-index: 0;
	-webkit-transition: 0.65s ease;
	-o-transition: 0.65s ease;
	transition: 0.65s ease;
}
.btn::before {
	content: '';
	position: absolute;
	bottom: -290px;
	left: 0;
	width: 100%;
	height: 250px;
	background: #fff;
	z-index: -1;
	border-radius: 50%;
	pointer-events: none;
	-webkit-transition: 0.85s ease;
	-o-transition: 0.85s ease;
	transition: 0.85s ease;
}

.btn:hover {
	color: #000;
	border-color: transparent;
}

.btn:hover::before {
	bottom: 0;
	border-radius: 0;
}

/** ================== // HEADER CSS START // ================== **/
.header-logo {
	text-align: center;
	padding: 2.083vw 0px 6.181vw;
}
.header-logo img {
	width: 7.569vw;
}
/** ================== // HEADER CSS END // ================== **/

/** ================== // BANNER CSS START // ================== **/
.banner-section {
	padding-bottom: 3.889vw;
}
.banner-wrapper {
	text-align: center;
}
.banner-wrapper h1 {
	margin-bottom: 3.264vw;
}
.banner-wrapper p {
	width: 69.958vw;
	margin: 0 auto 3.194vw;
	font-size: 1.389vw;
	line-height: 1.736vw;
	font-weight: 500;
	font-family: 'Gotham Medium';
}
.scroll-next-btn img {
	width: 5.556vw;
}
.scroll-next-btn a img {
	-webkit-transition: 0.2s ease-in-out;
	-o-transition: 0.2s ease-in-out;
	transition: 0.2s ease-in-out;
}
.scroll-next-btn a:hover img {
	opacity: 0.7;
}
/** ================== // BANNER CSS END // ================== **/

/** ================== // MARKETING SOLUTIONS SECTION CSS START // ================== **/
.bg-black {
	background: #000;
}
.marketing-solutions-section {
	padding: 9.028vw 0 6.458vw;
}
.marketing-solutions-wrapper {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-wrap: wrap;
}
.marketing-solutions-img {
	width: 34.028vw;
}
.marketing-solutions-img img {
	width: 100%;
	-webkit-transition: 0.35s ease;
	-o-transition: 0.35s ease;
	transition: 0.35s ease;
}
.marketing-solutions-img img:hover {
	-webkit-transform: translateY(-5px);
	-ms-transform: translateY(-5px);
	transform: translateY(-5px);
	-webkit-filter: opacity(0.8);
	filter: opacity(0.8);
}
.marketing-solutions-info {
	width: 38.458vw;
}
.marketing-solutions-info h2 {
	font-size: 4.167vw;
	line-height: 4.167vw;
	margin: 0 0 4.861vw;
}
.marketing-solutions-info h4 {
	font-size: 1.389vw;
	line-height: 1.944vw;
	color: #fff;
	margin: 0 0 1.458vw;
	font-family: 'Gotham Medium';
	font-weight: 500;
}
.marketing-solutions-info p {
	color: #fff;
	font-family: 'Gotham Light';
	font-weight: 300;
	padding-right: 3.394vw;
}
.talk-btn {
	margin: 2.083vw 0 0;
}
/** ================== // MARKETING SOLUTIONS SECTION CSS END // ================== **/

/** ================== // BRANDS PARTNER SECTION CSS START // ================== **/
.divider-dash-line {
	width: 59.861vw;
	border-top: 0.139vw dashed rgba(255, 255, 255, 0.32);
	margin: auto;
}
.brands-partner-wrapper {
	padding: 6.493vw 0 0;
	text-align: center;
}
.brands-partner-wrapper h4 {
	color: #fff;
	font-size: 1.528vw;
	line-height: 1.944vw;
	margin: 0 0 3.056vw;
	font-family: 'Gotham Medium';
	font-weight: 500;
}
.brands-partner-row {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.brands-partner-row + .brands-partner-row {
	margin-top: 2.5vw;
}
.brands-partner-section {
	padding: 0 0 5.972vw;
}
.brands-partner-logo img {
	-webkit-transition: 0.35s ease;
	-o-transition: 0.35s ease;
	transition: 0.35s ease;
}
.brands-partner-logo img:hover {
	-webkit-filter: brightness(0.5);
	filter: brightness(0.5);
	-webkit-transform: translateY(-2px);
	-ms-transform: translateY(-2px);
	transform: translateY(-2px);
}
/** ================== // BRANDS PARTNER SECTION CSS END // ================== **/

/** ================== // DATA INFO SECTION CSS START // ================== **/
.data-info-section {
	background: #32327a;
	padding: 8.681vw 0 6.528vw;
}
/* .data-info-section .container {
    max-width: 63.958vw;
} */
.data-info-left {
	width: 22.917vw;
	margin-left: 10vw;
}
.data-info-right {
	width: 40.625vw;
}
.data-number p.align-left {
	text-align: left;
}
.data-info-wrapper {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	flex-wrap: wrap;
	position: sticky;
	top: 50px;
	background: #32327a;
}
.data-info-number-list {
	display: flex;
	align-items: center;
	justify-content: center;
}
.data-numbe {
	position: relative;
}
.data-number span {
	font-size: 5.486vw;
	line-height: 1;
	color: #b4ec9d;
	font-family: 'Gotham Bold';
	font-weight: bold;
	margin: 0;
	display: block;
}
.data-number p {
	font-size: 0.972vw;
	line-height: 1.528vw;
	color: #b4ec9d;
	margin: -5px 0 0;
}
.data-number {
	text-align: right;
	margin-left: 3.125vw;
}
.data-info-number-list .icon {
	width: 4.583vw;
}
.data-info-number-list:nth-child(2) {
	margin: 4.167vw 1.389vw;
	padding: 2.472vw 0;
	border-top: 0.139vw dashed rgba(255, 255, 255, 0.32);
	border-bottom: 0.139vw dashed rgba(255, 255, 255, 0.32);
}

.data-number.down span {
	color: #fe7b94;
}
.data-info-right h2 {
	font-size: 5.208vw;
	line-height: 5.208vw;
	letter-spacing: -0.052vw;
	margin: 0 0 2.778vw;
}
.data-info-right p {
	color: #fff;
	font-size: 1.389vw;
	line-height: 1.944vw;
	margin: 0 0 2.778vw;
}
.data-info-right p strong {
	display: block;
	font-size: 3.472vw;
	line-height: 1;
	font-family: 'Gotham Black';
	font-weight: 900;
}
.data-info-right p {
	color: #fff;
	font-size: 1.389vw;
	line-height: 1.944vw;
	margin: 0 0 2.778vw;
	padding-right: 0;
}
.data-info-wrapper + .data-info-wrapper {
	padding-top: 13.125vw;
}
.data-info-wrapper + .data-info-wrapper .data-info-right {
	width: 37.625vw;
}
.find-out-btn {
	text-align: center;
	margin-top: 7.222vw;
	width: 100%;
}
.data-info-wrapper + .data-info-wrapper .data-info-left {
	margin-left: 0;
	margin-right: 10vw;
}
/** ================== // DATA INFO SECTION CSS END // ================== **/

/** ================== // TESTIMONIALS SECTION CSS START // ================== **/
.testimonials-section {
	background: #fff;
	padding: 8.472vw 0 9.097vw;
}
.testimonials-wrapper {
	text-align: center;
}
.testimonials-wrapper h2 {
	color: #20204a;
	font-size: 3.472vw;
	line-height: 3.889vw;
	font-family: 'Gotham Bold';
	font-weight: bold;
	margin: 0 0 3.167vw;
}
.testimonials-slide p {
	color: #20204a;
	font-family: 'Gotham Book';
	font-weight: normal;
	font-style: italic;
	font-size: 2.222vw;
	line-height: 2.917vw;
	letter-spacing: 0;
}
.testimonials-slider {
	width: 63.167vw;
	margin: 0 auto;
	position: relative;
	padding: 0 0 8.889vw;
}
.testimonials-slider::before {
	content: '';
	position: absolute;
	top: -7.639vw;
	left: -5.25vw;
	width: 6.806vw;
	height: 14.861vw;
	background-image: url(../images/quote-icon-left.svg);
	background-repeat: no-repeat;
	background-size: 100%;
	pointer-events: none;
}
.testimonials-slider::after {
	content: '';
	position: absolute;
	bottom: 1.639vw;
	right: -7.639vw;
	width: 6.806vw;
	height: 14.861vw;
	background-image: url(../images/quote-icon-right.svg);
	background-repeat: no-repeat;
	background-size: 100%;
	pointer-events: none;
}
.testimonials-slide {
	padding: 0 2vw;
}
.testimonials-slider .slick-dots {
	bottom: 0;
	width: 29.236vw;
	background: rgba(32, 32, 74, 0.3);
	left: 50%;
	transform: translateX(-50%);
}
.testimonials-slider .slick-dots li {
	margin: 0;
}
.testimonials-slider .slick-dots li button {
	width: max-content;
	height: 3px;
}
.testimonials-slider .slick-dots li.slick-active button {
	background: #20204a;
}
.swiper-scrollbar {
	background: #d6d6d6;
	margin-top: 1.903vw;
}
.swiper-scrollbar-drag {
	background: #20204a;
}
.testimonials-slider .swiper-scrollbar {
	border-radius: 0.347vw;
	height: 0.208vw;
	width: 29.236vw;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
}
.testimonials-slider .swiper-button-prev {
	width: 5.556vw;
	height: 5.556vw;
	background-image: url(../images/slider-left-arrow.svg);
	background-repeat: no-repeat;
	background-size: cover;
	opacity: 1;
	cursor: pointer;
	bottom: 0;
	top: auto;
	right: auto;
	left: 43%;
	transform: translateX(-50%);
}
.testimonials-slider .swiper-button-next {
	width: 5.556vw;
	height: 5.556vw;
	background-image: url(../images/slider-right-arrow.svg);
	background-repeat: no-repeat;
	background-size: cover;
	opacity: 1;
	cursor: pointer;
	bottom: 0;
	top: auto;
	right: auto;
	left: 57%;
	transform: translateX(-50%);
}
/** ================== // TESTIMONIALS SECTION CSS END // ================== **/

/** ================== // DIGITAL MARKETING SECTION CSS START // ================== **/
a.tabs-dropdown {
	display: none;
}
.digital-marketing-section {
	background: #20204a;
	padding: 6.181vw 0 6.875vw;
}
.digital-marketing-wrapper {
	text-align: center;
}
.digital-marketing-wrapper h2 {
	font-size: 3.472vw;
	line-height: 3.889vw;
	font-family: 'Gotham Bold';
	font-weight: bold;
	margin: 0 0 2.5vw;
}
.nav-tabs {
	border-bottom: 0;
}
.digital-marketing-tabs-nav {
	width: 42.014vw;
	background: #32327a;
	border-radius: 1.736vw;
	padding: 4.514vw;
	z-index: 1;
	position: relative;
}
.nav-tabs .nav-item a {
	color: rgba(225, 255, 255, 0.52);
	font-size: 1.528vw;
	line-height: 1.944vw;
	font-family: 'Gotham Book';
	font-weight: normal;
	padding: 0;
	border: 0;
}
.nav-tabs .nav-item:not(:last-child) {
	margin-bottom: 6.25vw;
}
.nav-tabs .nav-item {
	width: 100%;
	text-align: left;
}
.nav-tabs .nav-item a.active {
	background: transparent;
	color: #fff;
	position: relative;
}
.nav-tabs .nav-item a.active::before {
	content: '';
	position: absolute;
	right: -5.3vw;
	top: 9px;
	width: 33px;
	height: 33px;
	background: #32327a;
	transform: rotate(45deg);
}
.digital-marketing-tabs-nav-info {
	background: #fff;
	width: 47.569vw;
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	border-radius: 1.736vw;
	z-index: 0;
	padding: 4.514vw;
	display: flex;
	align-items: center;
}
.digital-marketing-tabs {
	position: relative;
}
.digital-marketing-tabs-content ul {
	padding: 0;
}
.digital-marketing-tabs-content ul li {
	margin: 0 0 1.979vw;
	padding: 0 0 0 3.083vw;
	color: #32327a;
	font-size: 1.35vw;
	line-height: 1.736vw;
	font-family: 'Gotham Book';
	font-weight: normal;
	text-align: left;
}
.digital-marketing-tabs-content ul li::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 1.628vw;
	height: 1.628vw;
	background-image: url(../images/list-icon.svg);
	background-repeat: no-repeat;
	background-size: 100%;
}
.digital-marketing-tabs-content {
	width: 28vw;
	margin: 0 0 0 11vw;
}
.digital-marketing-tabs-content ul li ul li::before {
	display: none;
}
.digital-marketing-tabs-content ul li ul li {
	padding-left: 0;
	width: 50%;
	list-style-type: disc;
	margin: 1.458vw 0 0;
	font-size: 1.1vw;
}
.digital-marketing-tabs-content ul li ul {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
	margin-left: 1vw;
}
/** ================== // DIGITAL MARKETING SECTION CSS END // ================== **/

/** ================== // READY SECTION CSS START // ================== **/
.ready-section {
	background: #d7bfed;
	padding: 8.403vw 0;
}
.ready-wrapper {
	text-align: center;
}
.ready-wrapper h2 {
	color: #20204a;
	margin: 0 0 2.639vw;
}
.ready-btn .btn {
	border-color: #20204a;
	color: #20204a;
}
.ready-btn .btn::before {
	background: #20204a;
}
.ready-btn .btn:hover {
	color: #fff;
	border-color: transparent;
}
.heading-animate {
	overflow: hidden;
	-webkit-transition: 1s;
	-o-transition: 1s;
	transition: 1s;
}
.heading-animate span {
	display: block;
	-webkit-transform: translateY(120%) scale(1.2);
	-ms-transform: translateY(120%) scale(1.2);
	transform: translateY(120%) scale(1.2);
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	text-align: center;
}
.heading-animate.aos-animate span {
	animation-name: titleAnimateCenter;
	animation-duration: 1s;
	animation-fill-mode: both;
	animation-timing-function: ease-in-out;
	animation-iteration-count: 1;
}
@-webkit-keyframes titleAnimateCenter {
	0% {
		-webkit-transform: translateY(120%) scale(1.2);
		-ms-transform: translateY(120%) scale(1.2);
		transform: translateY(120%) scale(1.2);
		-webkit-transition: 0.5s;
		-o-transition: 0.5s;
		transition: 0.5s;
	}

	100% {
		-webkit-transform: translateY(0%) scale(1);
		-ms-transform: translateY(0%) scale(1);
		transform: translateY(0%) scale(1);
	}
}
@keyframes titleAnimateCenter {
	0% {
		-webkit-transform: translateY(120%) scale(1.2);
		-ms-transform: translateY(120%) scale(1.2);
		transform: translateY(120%) scale(1.2);
		-webkit-transition: 0.5s;
		-o-transition: 0.5s;
		transition: 0.5s;
	}

	100% {
		-webkit-transform: translateY(0%) scale(1);
		-ms-transform: translateY(0%) scale(1);
		transform: translateY(0%) scale(1);
	}
}

.hidedesktop {
	display: none;
}
/** ================== // READY SECTION CSS END // ================== **/
.data-info-wrapper-d-flex {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 100%;
}
.brands-partner-row.desktop-hide {
	display: none;
}

/** MODAL STYLES */
.modal-header {
	border: 0;
}

.close {
	color: #fff;
	transition: opacity ease-in-out 0.3s;
}

.close:hover {
	color: #fff;
	opacity: 1;
}

.modal-content {
	background: url('../images/grid-pattern.svg') 50%, linear-gradient(#3d3da6, #323279);
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
}

/** FORM STYLES */
.contact-form {
	font-family: 'Gotham Book';
	font-size: 1.125rem;
	line-height: normal;
	color: #fff;
	padding-bottom: 2rem;
}

.contact-form input,
.contact-form select,
.contact-form textarea,
#mktChallengesPlaceholder {
	display: block;
	width: 100%;
	border: 0;
	margin: 0;
	background: rgba(255, 255, 255, 0.25);
	padding: 1.2rem 1.75rem;
	border-radius: 3px;
	font-family: inherit;
	font-size: inherit;
	color: inherit;
}

.contact-form input::placeholder,
.contact-form textarea::placeholder {
	opacity: 0.3;
	color: #fff;
}

.contact-form input.is-invalid,
.contact-form select.is-invalid,
.contact-form textarea.is-invalid,
#mktChallengesPlaceholder.is-invalid {
	border: solid 1px red;
}

.contact-form select {
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;

	background: url('../images/angle-down.svg') right 20px center no-repeat, rgba(255, 255, 255, 0.25);
}

#mktChallengesPlaceholder {
	cursor: pointer;
	background: url('../images/angle-down.svg') right 20px center no-repeat, rgba(255, 255, 255, 0.25);
}

.custom-checkbox input {
	display: none;
}

.custom-checkbox label {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	column-gap: 1.25rem;
}

.custom-checkbox .checkmark {
	display: block;
	flex: 0 0 37px;
	height: 37px;
	background: transparent;
	border: solid 1px #fff;
}

.custom-checkbox input:checked ~ label .checkmark {
	background: url('../images/check.svg') center center no-repeat, #9789c7;
}

.multiple-select-label {
	cursor: pointer;
	font-family: 'Gotham Light';
	font-size: 1.25rem;
	color: #9595c7;
	text-align: center;
	margin-bottom: 0.5rem;
}

.multiple-select-label + input {
	cursor: pointer;
}

.multiple-select-content {
	background: rgba(255, 255, 255, 0.25);
	padding: 1.125rem 2rem;
	margin-top: 0.5rem;
	border-radius: 3px;
	max-height: 250px;
	overflow-y: auto;
}

.multiple-select-content:not(.show) {
	display: none;
}

.multiple-select-content::-webkit-scrollbar {
	width: 10px;
}

.multiple-select-content::-webkit-scrollbar-track {
	background: #b5c0f9;
}

.multiple-select-content::-webkit-scrollbar-thumb {
	background: #5757c8;
}

.multiple-select-content::-webkit-scrollbar-thumb:hover {
	background: #323279;
}

.multiple-select-content > div:not(:last-child) {
	margin-bottom: 1.25rem;
}

.field-error-message {
	display: none;
	font-size: 0.875rem;
	color: red;
	margin-top: 0.5rem;
}

.submit-button {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 60px;
	cursor: pointer;
	background: linear-gradient(90deg, #ffc958, #ffab26);
	border-radius: 4px;
	box-shadow: 0 2px 25px rgba(0, 0, 0, 0.25);
	color: #242424;
	margin: 1.25rem auto 1rem;
	padding: 0.5rem;
	width: 100%;

	font-family: 'Gotham Bold';
	font-size: 20px;
	font-weight: normal;
	transition: all ease-in-out 0.3s;
}

.submit-button span {
	display: flex;
	align-items: center;
	justify-content: center;
}

.submit-button .loading {
	display: none;
}

.submit-button .loading img {
	margin-top: -2px;
}

.submit-button:hover,
.submit-button:disabled {
	filter: brightness(0.8);
}

.form-sent-wrap {
	display: none;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 1.2rem 1.75rem;
	border-radius: 3px;
	font-family: 'Gotham Book';
	font-size: 2.25rem;
	line-height: 1.2;
	color: #fff;
	padding: 3rem 0 5rem;
}

.form-error-wrap {
	display: none;
	text-align: center;
	color: red;
}

.grecaptcha-badge {
	visibility: hidden;
}
