* {
  box-sizing: border-box;
}

:focus {
  box-shadow: none;
  border-color: inherit;
  outline: none !important;
}

figure {
  margin: 0;
}

textarea.form-control {
  resize: none;
  overflow: hidden;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

html, body {
  width: 100%;
}

body {
  background: #20204a;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: Gotham-Book;
  font-weight: normal;
  display: flex;
}

.footer {
  margin-top: auto;
}

img {
  max-width: 100%;
}

a {
  color: #3b4b9b;
  transition: all .45s;
}

a:hover {
  color: #3b4b9b;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  color: #2e2e2e;
  margin: 0 0 15px;
  padding: 0;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  color: #2e2e2e;
}

h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover {
  color: #c39f5e;
}

h1 {
  color: #f2f2f2;
  letter-spacing: -.139vw;
  font-family: Gotham Black;
  font-size: 6.944vw;
  font-weight: 900;
  line-height: 6.597vw;
}

h2 {
  color: #f2f2f2;
  letter-spacing: -.042vw;
  margin-bottom: 20px;
  font-family: Gotham Black;
  font-size: 4.167vw;
  font-weight: 900;
  line-height: 4.167vw;
}

h3 {
  color: #f2f2f2;
  margin-bottom: 20px;
  font-family: Gotham Bold;
  font-size: 1.6em;
  font-weight: bold;
  line-height: 1.25em;
}

h4 {
  font-family: Gotham Bold;
  font-size: 22px;
  font-weight: bold;
  line-height: 28px;
}

h5, h6 {
  font-family: Gotham Bold;
  font-size: 16px;
  font-weight: bold;
  line-height: 19.36px;
}

p {
  color: #f2f2f2;
  letter-spacing: .12px;
  margin-bottom: 25px;
  font-family: Gotham Book;
  font-size: 1.111vw;
  font-style: normal;
  font-weight: normal;
  line-height: 1.528vw;
}

p a {
  color: #3b4b9b;
  text-decoration: underline;
}

p a:hover {
  color: #3b4b9b;
  text-decoration: none;
}

b, strong {
  font-family: Gotham Bold;
  font-weight: bold;
}

p:last-child {
  margin-bottom: 0;
}

ul {
  margin: 0;
  padding: 0 0 20px;
}

ol {
  margin: 0;
  padding-bottom: 0;
  padding-left: 40px;
}

ol li {
  color: #3b4b9b;
  margin: 0;
  padding: 0;
  font-size: 1.3em;
  font-style: normal;
  line-height: 1.55em;
  list-style: decimal-leading-zero;
  position: relative;
}

li {
  color: #3b4b9b;
  margin: 0;
  padding: 0;
  font-size: 1.3em;
  font-style: normal;
  line-height: 1.55em;
  list-style: none;
  position: relative;
}

li:last-child {
  margin-bottom: 0;
}

button {
  background-color: #0000;
  border: none;
  padding: 0;
}

.btn {
  color: #f2f2f2;
  z-index: 0;
  -o-transition: .65s ease;
  border: .139vw solid #f2f2f2;
  border-radius: 3.125vw;
  justify-content: center;
  align-items: center;
  min-width: 14.167vw;
  height: 4.444vw;
  margin: 0;
  padding: 0;
  font-family: Gotham Medium;
  font-size: 1.35vw;
  font-weight: 500;
  transition: all .65s;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.btn:before {
  content: "";
  z-index: -1;
  pointer-events: none;
  -o-transition: .85s ease;
  background: #fff;
  border-radius: 50%;
  width: 100%;
  height: 250px;
  transition: all .85s;
  position: absolute;
  bottom: -290px;
  left: 0;
}

.btn:hover {
  color: #000;
  border-color: #0000;
}

.btn:hover:before {
  border-radius: 0;
  bottom: 0;
}

.header-logo {
  text-align: center;
  padding: 2.083vw 0 6.181vw;
}

.header-logo img {
  width: 7.569vw;
}

.banner-section {
  padding-bottom: 3.889vw;
}

.banner-wrapper {
  text-align: center;
}

.banner-wrapper h1 {
  margin-bottom: 3.264vw;
}

.banner-wrapper p {
  width: 69.958vw;
  margin: 0 auto 3.194vw;
  font-family: Gotham Medium;
  font-size: 1.389vw;
  font-weight: 500;
  line-height: 1.736vw;
}

.scroll-next-btn img {
  width: 5.556vw;
}

.scroll-next-btn a img {
  -o-transition: .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.scroll-next-btn a:hover img {
  opacity: .7;
}

.bg-black {
  background: #000;
}

.marketing-solutions-section {
  padding: 9.028vw 0 6.458vw;
}

.marketing-solutions-wrapper {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.marketing-solutions-img {
  width: 34.028vw;
}

.marketing-solutions-img img {
  -o-transition: .35s ease;
  width: 100%;
  transition: all .35s;
}

.marketing-solutions-img img:hover {
  filter: opacity(.8);
  transform: translateY(-5px);
}

.marketing-solutions-info {
  width: 38.458vw;
}

.marketing-solutions-info h2 {
  margin: 0 0 4.861vw;
  font-size: 4.167vw;
  line-height: 4.167vw;
}

.marketing-solutions-info h4 {
  color: #fff;
  margin: 0 0 1.458vw;
  font-family: Gotham Medium;
  font-size: 1.389vw;
  font-weight: 500;
  line-height: 1.944vw;
}

.marketing-solutions-info p {
  color: #fff;
  padding-right: 3.394vw;
  font-family: Gotham Light;
  font-weight: 300;
}

.talk-btn {
  margin: 2.083vw 0 0;
}

.divider-dash-line {
  border-top: .139vw dashed #ffffff52;
  width: 59.861vw;
  margin: auto;
}

.brands-partner-wrapper {
  text-align: center;
  padding: 6.493vw 0 0;
}

.brands-partner-wrapper h4 {
  color: #fff;
  margin: 0 0 3.056vw;
  font-family: Gotham Medium;
  font-size: 1.528vw;
  font-weight: 500;
  line-height: 1.944vw;
}

.brands-partner-row {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.brands-partner-row + .brands-partner-row {
  margin-top: 2.5vw;
}

.brands-partner-section {
  padding: 0 0 5.972vw;
}

.brands-partner-logo img {
  -o-transition: .35s ease;
  transition: all .35s;
}

.brands-partner-logo img:hover {
  filter: brightness(.5);
  transform: translateY(-2px);
}

.data-info-section {
  background: #32327a;
  padding: 8.681vw 0 6.528vw;
}

.data-info-left {
  width: 22.917vw;
  margin-left: 10vw;
}

.data-info-right {
  width: 40.625vw;
}

.data-number p.align-left {
  text-align: left;
}

.data-info-wrapper {
  background: #32327a;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
  position: sticky;
  top: 50px;
}

.data-info-number-list {
  justify-content: center;
  align-items: center;
  display: flex;
}

.data-numbe {
  position: relative;
}

.data-number span {
  color: #b4ec9d;
  margin: 0;
  font-family: Gotham Bold;
  font-size: 5.486vw;
  font-weight: bold;
  line-height: 1;
  display: block;
}

.data-number p {
  color: #b4ec9d;
  margin: -5px 0 0;
  font-size: .972vw;
  line-height: 1.528vw;
}

.data-number {
  text-align: right;
  margin-left: 3.125vw;
}

.data-info-number-list .icon {
  width: 4.583vw;
}

.data-info-number-list:nth-child(2) {
  border-top: .139vw dashed #ffffff52;
  border-bottom: .139vw dashed #ffffff52;
  margin: 4.167vw 1.389vw;
  padding: 2.472vw 0;
}

.data-number.down span {
  color: #fe7b94;
}

.data-info-right h2 {
  letter-spacing: -.052vw;
  margin: 0 0 2.778vw;
  font-size: 5.208vw;
  line-height: 5.208vw;
}

.data-info-right p {
  color: #fff;
  margin: 0 0 2.778vw;
  font-size: 1.389vw;
  line-height: 1.944vw;
}

.data-info-right p strong {
  font-family: Gotham Black;
  font-size: 3.472vw;
  font-weight: 900;
  line-height: 1;
  display: block;
}

.data-info-right p {
  color: #fff;
  margin: 0 0 2.778vw;
  padding-right: 0;
  font-size: 1.389vw;
  line-height: 1.944vw;
}

.data-info-wrapper + .data-info-wrapper {
  padding-top: 13.125vw;
}

.data-info-wrapper + .data-info-wrapper .data-info-right {
  width: 37.625vw;
}

.find-out-btn {
  text-align: center;
  width: 100%;
  margin-top: 7.222vw;
}

.data-info-wrapper + .data-info-wrapper .data-info-left {
  margin-left: 0;
  margin-right: 10vw;
}

.testimonials-section {
  background: #fff;
  padding: 8.472vw 0 9.097vw;
}

.testimonials-wrapper {
  text-align: center;
}

.testimonials-wrapper h2 {
  color: #20204a;
  margin: 0 0 3.167vw;
  font-family: Gotham Bold;
  font-size: 3.472vw;
  font-weight: bold;
  line-height: 3.889vw;
}

.testimonials-slide p {
  color: #20204a;
  letter-spacing: 0;
  font-family: Gotham Book;
  font-size: 2.222vw;
  font-style: italic;
  font-weight: normal;
  line-height: 2.917vw;
}

.testimonials-slider {
  width: 63.167vw;
  margin: 0 auto;
  padding: 0 0 8.889vw;
  position: relative;
}

.testimonials-slider:before {
  content: "";
  pointer-events: none;
  background-image: url("quote-icon-left.65280ac4.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 6.806vw;
  height: 14.861vw;
  position: absolute;
  top: -7.639vw;
  left: -5.25vw;
}

.testimonials-slider:after {
  content: "";
  pointer-events: none;
  background-image: url("quote-icon-right.5ba5c3f6.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 6.806vw;
  height: 14.861vw;
  position: absolute;
  bottom: 1.639vw;
  right: -7.639vw;
}

.testimonials-slide {
  padding: 0 2vw;
}

.testimonials-slider .slick-dots {
  background: #20204a4d;
  width: 29.236vw;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.testimonials-slider .slick-dots li {
  margin: 0;
}

.testimonials-slider .slick-dots li button {
  width: max-content;
  height: 3px;
}

.testimonials-slider .slick-dots li.slick-active button {
  background: #20204a;
}

.swiper-scrollbar {
  background: #d6d6d6;
  margin-top: 1.903vw;
}

.swiper-scrollbar-drag {
  background: #20204a;
}

.testimonials-slider .swiper-scrollbar {
  border-radius: .347vw;
  width: 29.236vw;
  height: .208vw;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.testimonials-slider .swiper-button-prev {
  opacity: 1;
  cursor: pointer;
  background-image: url("slider-left-arrow.98406a3a.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 5.556vw;
  height: 5.556vw;
  inset: auto auto 0 43%;
  transform: translateX(-50%);
}

.testimonials-slider .swiper-button-next {
  opacity: 1;
  cursor: pointer;
  background-image: url("slider-right-arrow.90feb014.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 5.556vw;
  height: 5.556vw;
  inset: auto auto 0 57%;
  transform: translateX(-50%);
}

a.tabs-dropdown {
  display: none;
}

.digital-marketing-section {
  background: #20204a;
  padding: 6.181vw 0 6.875vw;
}

.digital-marketing-wrapper {
  text-align: center;
}

.digital-marketing-wrapper h2 {
  margin: 0 0 2.5vw;
  font-family: Gotham Bold;
  font-size: 3.472vw;
  font-weight: bold;
  line-height: 3.889vw;
}

.nav-tabs {
  border-bottom: 0;
}

.digital-marketing-tabs-nav {
  z-index: 1;
  background: #32327a;
  border-radius: 1.736vw;
  width: 42.014vw;
  padding: 4.514vw;
  position: relative;
}

.nav-tabs .nav-item a {
  color: #e1ffff85;
  border: 0;
  padding: 0;
  font-family: Gotham Book;
  font-size: 1.528vw;
  font-weight: normal;
  line-height: 1.944vw;
}

.nav-tabs .nav-item:not(:last-child) {
  margin-bottom: 6.25vw;
}

.nav-tabs .nav-item {
  text-align: left;
  width: 100%;
}

.nav-tabs .nav-item a.active {
  color: #fff;
  background: none;
  position: relative;
}

.nav-tabs .nav-item a.active:before {
  content: "";
  background: #32327a;
  width: 33px;
  height: 33px;
  position: absolute;
  top: 9px;
  right: -5.3vw;
  transform: rotate(45deg);
}

.digital-marketing-tabs-nav-info {
  z-index: 0;
  background: #fff;
  border-radius: 1.736vw;
  align-items: center;
  width: 47.569vw;
  height: 100%;
  padding: 4.514vw;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.digital-marketing-tabs {
  position: relative;
}

.digital-marketing-tabs-content ul {
  padding: 0;
}

.digital-marketing-tabs-content ul li {
  color: #32327a;
  text-align: left;
  margin: 0 0 1.979vw;
  padding: 0 0 0 3.083vw;
  font-family: Gotham Book;
  font-size: 1.35vw;
  font-weight: normal;
  line-height: 1.736vw;
}

.digital-marketing-tabs-content ul li:before {
  content: "";
  background-image: url("list-icon.b9faa7a9.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 1.628vw;
  height: 1.628vw;
  position: absolute;
  top: 0;
  left: 0;
}

.digital-marketing-tabs-content {
  width: 28vw;
  margin: 0 0 0 11vw;
}

.digital-marketing-tabs-content ul li ul li:before {
  display: none;
}

.digital-marketing-tabs-content ul li ul li {
  width: 50%;
  margin: 1.458vw 0 0;
  padding-left: 0;
  font-size: 1.1vw;
  list-style-type: disc;
}

.digital-marketing-tabs-content ul li ul {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 1vw;
  display: flex;
}

.ready-section {
  background: #d7bfed;
  padding: 8.403vw 0;
}

.ready-wrapper {
  text-align: center;
}

.ready-wrapper h2 {
  color: #20204a;
  margin: 0 0 2.639vw;
}

.ready-btn .btn {
  color: #20204a;
  border-color: #20204a;
}

.ready-btn .btn:before {
  background: #20204a;
}

.ready-btn .btn:hover {
  color: #fff;
  border-color: #0000;
}

.heading-animate {
  -o-transition: 1s;
  transition: all 1s;
  overflow: hidden;
}

.heading-animate span {
  -o-transition: .5s;
  text-align: center;
  transition: all .5s;
  display: block;
  transform: translateY(120%)scale(1.2);
}

.heading-animate.aos-animate span {
  animation-name: titleAnimateCenter;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

@keyframes titleAnimateCenter {
  0% {
    -o-transition: .5s;
    transition: all .5s;
    transform: translateY(120%)scale(1.2);
  }

  100% {
    transform: translateY(0%)scale(1);
  }
}

.hidedesktop {
  display: none;
}

.data-info-wrapper-d-flex {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  display: flex;
}

.brands-partner-row.desktop-hide {
  display: none;
}

.modal-header {
  border: 0;
}

.close {
  color: #fff;
  transition: opacity .3s ease-in-out;
}

.close:hover {
  color: #fff;
  opacity: 1;
}

.modal-content {
  background: url("grid-pattern.7b2f1970.svg") 50%, linear-gradient(#3d3da6, #323279);
  box-shadow: 0 0 20px #00000040;
}

.contact-form {
  color: #fff;
  padding-bottom: 2rem;
  font-family: Gotham Book;
  font-size: 1.125rem;
  line-height: normal;
}

.contact-form input, .contact-form select, .contact-form textarea, #mktChallengesPlaceholder {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background: #ffffff40;
  border: 0;
  border-radius: 3px;
  width: 100%;
  margin: 0;
  padding: 1.2rem 1.75rem;
  display: block;
}

.contact-form input::placeholder, .contact-form textarea::placeholder {
  opacity: .3;
  color: #fff;
}

.contact-form input.is-invalid, .contact-form select.is-invalid, .contact-form textarea.is-invalid, #mktChallengesPlaceholder.is-invalid {
  border: 1px solid red;
}

.contact-form select {
  appearance: none;
  background: url("angle-down.e4f0b32f.svg") right 20px center no-repeat, #ffffff40;
}

#mktChallengesPlaceholder {
  cursor: pointer;
  background: url("angle-down.e4f0b32f.svg") right 20px center no-repeat, #ffffff40;
}

.custom-checkbox input {
  display: none;
}

.custom-checkbox label {
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1.25rem;
  display: flex;
}

.custom-checkbox .checkmark {
  background: none;
  border: 1px solid #fff;
  flex: 0 0 37px;
  height: 37px;
  display: block;
}

.custom-checkbox input:checked ~ label .checkmark {
  background: url("check.253b6ad7.svg") center no-repeat, #9789c7;
}

.multiple-select-label {
  cursor: pointer;
  color: #9595c7;
  text-align: center;
  margin-bottom: .5rem;
  font-family: Gotham Light;
  font-size: 1.25rem;
}

.multiple-select-label + input {
  cursor: pointer;
}

.multiple-select-content {
  background: #ffffff40;
  border-radius: 3px;
  max-height: 250px;
  margin-top: .5rem;
  padding: 1.125rem 2rem;
  overflow-y: auto;
}

.multiple-select-content:not(.show) {
  display: none;
}

.multiple-select-content::-webkit-scrollbar {
  width: 10px;
}

.multiple-select-content::-webkit-scrollbar-track {
  background: #b5c0f9;
}

.multiple-select-content::-webkit-scrollbar-thumb {
  background: #5757c8;
}

.multiple-select-content::-webkit-scrollbar-thumb:hover {
  background: #323279;
}

.multiple-select-content > div:not(:last-child) {
  margin-bottom: 1.25rem;
}

.field-error-message {
  color: red;
  margin-top: .5rem;
  font-size: .875rem;
  display: none;
}

.submit-button {
  cursor: pointer;
  color: #242424;
  background: linear-gradient(90deg, #ffc958, #ffab26);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 60px;
  margin: 1.25rem auto 1rem;
  padding: .5rem;
  font-family: Gotham Bold;
  font-size: 20px;
  font-weight: normal;
  transition: all .3s ease-in-out;
  display: flex;
  box-shadow: 0 2px 25px #00000040;
}

.submit-button span {
  justify-content: center;
  align-items: center;
  display: flex;
}

.submit-button .loading {
  display: none;
}

.submit-button .loading img {
  margin-top: -2px;
}

.submit-button:hover, .submit-button:disabled {
  filter: brightness(.8);
}

.form-sent-wrap {
  text-align: center;
  color: #fff;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  padding: 3rem 0 5rem;
  font-family: Gotham Book;
  font-size: 2.25rem;
  line-height: 1.2;
  display: none;
}

.form-error-wrap {
  text-align: center;
  color: red;
  display: none;
}

.grecaptcha-badge {
  visibility: hidden;
}
/*# sourceMappingURL=index.7e4ca5a3.css.map */
